"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PLAYER_COLORS = exports.Color = void 0;
var Color;
(function (Color) {
    Color["BLUE"] = "blue";
    Color["RED"] = "red";
    Color["YELLOW"] = "yellow";
    Color["GREEN"] = "green";
    Color["BLACK"] = "black";
    Color["PURPLE"] = "purple";
    Color["ORANGE"] = "orange";
    Color["PINK"] = "pink";
    Color["NEUTRAL"] = "neutral";
    Color["BRONZE"] = "bronze";
    Color["LRED"] = "lred";
    Color["LYELLOW"] = "lyellow";
    Color["LGREEN"] = "lgreen";
    Color["LBLUE"] = "lblue";
})(Color = exports.Color || (exports.Color = {}));
exports.PLAYER_COLORS = [Color.BLUE, Color.RED, Color.YELLOW, Color.GREEN, Color.BLACK, Color.PURPLE, Color.ORANGE, Color.PINK, Color.LRED, Color.LYELLOW, Color.LBLUE, Color.LGREEN];
